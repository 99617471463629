#projectExamplesWrapper{
  display:flex;
  justify-content:space-around;
  align-items: space-around;
  flex-direction:column;
  align-items: center;
  width:100%;
}
#projectBorder{
  display:none;
}
.techInProject{
  display:flex;
  flex-direction:row;
  justify-content: center;
  width:80%;
  max-width:400px;
  flex-wrap: wrap;
}
#rightSideInfo{
  width:50%;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 0;
  margin-left:10%;
}
.appDescription{
  font-size: 1.1rem;
  text-align: center;
}
#gitAndLiveLinks{
  display:flex;
  flex-direction: row;
  justify-content: center;
  width:100%;
  margin-top: 5px;
}
#gitAndLiveLinks a{
  padding:0 1% 0 1%;
}
#projectExample{
  display:flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width:100%;
  height:fit-content;
  padding: 2% 2%;
  z-index:0;
}
#projectImage{
  border:1px solid #192842;
  box-shadow: 10px 10px 5px #192842;
  z-index: 0;
}
#projects a{
  font-size: 1.5rem;
  padding:1%;
}
#projects a:hover{
  background-color: rgba(25, 78, 74, 1);
}
#projects p{
  font-size:1.2rem;
}
@media only screen and (max-width: 1500px) and (min-width:1401px){
  #projects a{
    font-size: 1.4rem;
  }
  #projects p{
    font-size:1.1rem;
  }
  #rightSideInfo{
    margin-left:7%;
  }
}
@media only screen and (max-width: 1400px) and (min-width:1201px){
  #projectImage{
    width:450px;
    height:auto;
  }
  #projects a{
    font-size: 1rem;
  }
  #projects p{
    font-size:1.1rem;
  }
  #rightSideInfo{
    margin-left:7%;
  }
}
@media only screen and (max-width: 1200px) and (min-width:1101px){
  #projectImage{
    width:400px;
    height:auto;
  }
  #projects a{
    font-size: 1rem;
  }
  #projects p{
    font-size:1.1rem;
  }
  #projects{
    width:80%;
  }
  #rightSideInfo{
    margin-left:7%;
  }
}
@media only screen and (max-width: 1100px) and (min-width:1001px){
  #projectImage{
    width:400px;
    height:auto;
  }
  #projects a{
    font-size: 1rem;
  }
  #projects p{
    font-size:1.1rem;
  }
  #projects{
    width:85%;
  }
  #rightSideInfo{
    margin-left:5%;
  }
}
@media only screen and (max-width: 1000px) and (min-width:901px){
  #projectImage{
    width:400px;
    height:auto;
  }
  #projects a{
    font-size: 1rem;
  }
  #projects p{
    font-size:1rem;
  }
  #projects h3{
    font-size:1.4rem;
  }
  #projects{
    width:89%;
  }
  #rightSideInfo{
    margin-left:3%;
  }
}
@media only screen and (max-width:900px){
  #projectImage{
    width:300px;
    height:auto;
  }
  #projects a{
    font-size: 1.5rem;
  }
  #projects p{
    font-size:1.2rem;
  }
  #projects h3{
    font-size:2.5rem;
    margin-bottom:10px;
    margin-top:10px;
  }
  #projects{
    width:89%;
  }
  #rightSideInfo{
    margin-left:7%;
    width:100%;
  }
  #projectExample{
    flex-direction:column;
    margin-top:15px;
    margin-bottom:15px;
  }
  .techChip{
    margin:3%;
  }
  #projectExamplesWrapper{
    width:85%;
  }
  #projectBorder{
    display:block;
    width:50%;
    height:2px;
    background-color:rgba(255, 255, 255, 0.5);
    margin-top:10%;
  }
}