#main{
  justify-content:center;
  display:flex;
  flex-direction: column;
  align-items: center;
  width:100%;
  padding-top:6%;
}
.mainBodySection{
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  width:75%;
  padding-top:10%;
}
#tylerDansby{
  margin-top:50px;
  font-size:5rem;
  font-style: italic;
}
@media only screen and (max-width:1050px) and (min-width:900px){
  #main{
    padding-top:10%;
  }
}
@media only screen and (max-width:899px) and (min-width:800px){
  #main{
    padding-top:10%;
  }
}
@media only screen and (max-width:799px){
  #main{
    padding-top:10%;
  }
  #tylerDansby{
    font-size:3rem;
  }
  .sectionTitle{
    font-size: 3rem;
  }
  .mainBodySection{
    width:65%;
  }
}