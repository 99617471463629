#aboutDescription{
  width:60%;
  margin-bottom:2%;
  text-align: center;
}
#aboutMe a{
  font-size: 1.5rem;
  padding:0.5%;
}
#aboutMe a:hover{
  background-color: rgba(25, 78, 74, 1);
}
@media only screen and (min-width:1600px){
  #aboutDescription{
    font-size:1.8rem;
  }
}
@media only screen and (max-width:800px){
  #aboutDescription{
    width:90%;
  }
}