#education h3{
    text-decoration:underline;
    text-align: center;
}
#education a{
    font-size:1.3rem;
    padding:1%;
}
#education a:hover{
    background-color:rgba(25, 78, 74, 1);
}
#school, #workExperience{
    margin-bottom:2%;
    margin-top:2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.subscript{
    margin-left:40px;
}
.dates{
    font-style: italic;
}
.jobContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.jobInfo, .schoolInfo{
    margin-left: 20px;
}
.sectionTitle{
    align-self: center;
}
.workTechList{
    display: flex;
    flex-direction: row;
    margin-left:40px;
}
.workTech{
    margin-right:3px;
}
.workTechChip{
    margin-top:5px;
    margin-left:5px;
    margin-right:5px;
    padding:10px;
    font-size:1rem;
    border-radius: 25px;
    background-color:#43506d;
}
.schoolContainer{
    display:flex;
    flex-direction:row;
}
#retriumLogo, #HMSLLogo, #sodexoLogo, #UHLogo{
    width:75px;
    height:75px;
    border:3px solid black;
    background-color: white;
}
#retriumLogo{
    padding:5px;
}
#sodexoLogo{
    padding:0 5px 10px 5px;
}
#UHLogo{
    padding: 5px;
}
.educationExperienceTitle{
    font-size: 2rem;
    align-self: center;
}
@media only screen and (max-width:550px) {
    #education{
        font-size: 1.2rem;
    }
    #retriumLogo, #HMSLLogo, #sodexoLogo, #UHLogo{
        width:50px;
        height:50px;
    }
    .workTechChip{
        font-size:0.8rem;
    }
    .educationExperienceTitle{
        font-size: 1.8rem;
        align-self: center;
    }
}