.header{
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content:space-between;
    width:100%;
    height:8%;
    max-height:100px;
    box-shadow: 5px 5px 10px #192842;
    position:fixed;
    background-color:#203354;
    z-index: 5;
}
#logoAndResume{
    width:15%;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
}
#pageLinks{
    width:40%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    padding:0;
    margin-right:2%;
}
#pageLinks a{
    margin:0;
}
#logo{
   margin:3% 0 0% 3%;
   padding:0%;
}
#logo:hover{
    background-color:rgba(25, 78, 74, 0);
}
#logoNestedImg{
    width:50px;
    height:auto;
}
#openSideLinks{
    width:50px;
    height:50px;
}
#svgWrapper{
    border:1px solid white;
    display:none;
    width:51px;
    height:51px;
    margin-right:2%;
}
#lineOne, #lineTwo, #lineThree{
    animation:none;
    transform-origin:center;
    stroke:white;
}
#pageLinks a, #resumeLink a{
    text-decoration: none;
    color: rgb(255, 255, 255);
    position: relative;
}
#pageLinks a:hover, #resumeLink a:hover{
    color: rgb(255, 255, 255);
}
#pageLinks a::before, #resumeLink a::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: rgb(255, 255, 255);
    transform: scaleX(0);
    transition: transform 0.3s ease;
    transform-origin: top left;
}
#pageLinks a:hover::before, #resumeLink a:hover::before {
    transform: scaleX(1);
}
 
@keyframes lineOneMovement{
    from{
        transform: translate(0px, 0px) rotate(0turn);
    }
    to{
        transform: translate(-7.5px, 7.5px) rotate(0.125turn);
    }
}
@keyframes lineOneMovementRev{
    from{
        transform: translate(-7.5px, 7.5px) rotate(0.125turn);
    }
    to{
        transform: translate(0px, 0px) rotate(0turn);
    }
}
@keyframes lineTwoMovement{
    from{
        transform: translate(0px, 0px) rotate(0turn);
    }
    to{
        transform: translate(-35px, 0px) rotate(0.25turn);
    } 
}
@keyframes lineTwoMovementRev{
    from{
        transform: translate(-35px, 0px) rotate(0.25turn);
    }
    to{
        transform: translate(0px, 0px) rotate(0turn);
    } 
}
@keyframes lineThreeMovement{
    from{
        transform: translate(0px, 0px) rotate(0.turn);
    }
    to{
        transform: translate(-7.5px, -6px) rotate(-0.125turn);
    }
}
@keyframes lineThreeMovementRev{
    from{
        transform: translate(-7.5px, -6px) rotate(-0.125turn);
    }
    to{
        transform: translate(0px, 0px) rotate(0turn);
    }
}

@media only screen and (max-width: 1050px){
    #pageLinks, #resumeLink{
        display:none;
    }
    #svgWrapper{
        display:block;
    }
}