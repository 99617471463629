#technologiesList{
  display:flex;
  flex-direction:row;
  justify-content: center;
  height: fit-content;
  width:100%;
  max-width:800px;
  flex-wrap: wrap;
}
.techLogoAndImg{
  display:flex;
  align-items: center;
  padding:2%;
}
.techLogo{
  width:auto;
  height:60px;
}
#skills ul{
  columns:2;
}
#javascriptLogo, #typescriptLogo, #gitLogo, #pythonLogo{
  padding:6%;
}
@media only screen and (min-width:1300px) {
  .techLogo{
    width:auto;
    height:100px;
  }
  .techLogoAndImg{
    font-size:2rem;
  }
}