*{
  box-sizing: border-box;
  margin:0;
  padding:0;
  font-family: 'Titillium Web', sans-serif;
}
html{
  background-color:#203354;
  color:white;
}
.App{
  position:absolute;
}
body{
  font-size:150%;
}
h1{
  font-size:2.5em;
  padding: 1% 1%;
}
h2{
  font-size:1.875em;
}
p{
  font-size:0.875em;
}
a{
  color:#fafafa;
  text-decoration: underline;
}
/* unvisited link */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #203354;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.fade-in-section{
  opacity: 0;
  transform: translatex(5vw);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
@keyframes pulse{
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(25, 78, 74, 1);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(25, 78, 74, 0.5);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}