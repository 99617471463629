#LoadScreen{
    width:100%;
    height:100%;
    position:fixed;
    background-color:#00001c;
    animation: load 2s forwards;
    display:flex;
    justify-content: center;
    align-items: center;
}
#TLogo{
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash 2s linear;
    width:200px;
    height:200px;
}
@keyframes dash {
    60%{
        opacity:1
    }
    99%{
        opacity:0;
    }
    100%{
        stroke-dashoffset: 0;
        opacity:0;
    }
}
@keyframes load{
    0%{
        z-index:6;
        background-color:rgba(0, 0, 28,1);
    }
    60%{
        background-color:rgba(0, 0, 28,1);
    }
    99%{
        background-color:rgba(0, 0, 28,0);
        z-index:6;
    }
    100%{
        background-color:rgba(0, 0, 28,0);
        display:none;
        z-index:-1;
    }
}
